.Blog-main-blog {
  padding: 20px 60px;
  overflow: hidden;
}

.font-blog {
  text-align: center;
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-first-section {
  display: flex;
  gap: 5%;
  padding-top: 20px;
  justify-content: space-between;
  align-items: stretch;
}
.newest-font {
  color: rgba(185, 126, 18, 1);
}

.first-blog-section-main {
  flex: 5;
  display: flex;
  gap: 4%;
  box-sizing: border-box;
}

.blog-second-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.posted-img {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.posted-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  flex: 3;
}

.posted-text {
  color: #6c7282;
  margin-top: 10px;
  font-size: 14px;
}

.col-blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
  gap: 15px;
}

.readmore-blog {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.readmore-blog img {
  width: 20px;
  height: 20px;
}

.col-blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  height: 100%;
}
.blog-section-container-blog {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 25px;
  margin-top: 3%;
}

.blog-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.blog-card-content-new {
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  flex-grow: 1;
}

.blog-description {
  margin-top: 12px;
  text-align: justify;
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  flex-grow: 1;
}

.blog-read-more {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: auto;
}

.blog-card-image {
  position: relative;
  text-align: center;
}

.blog-card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-post-date {
  margin-top: 8px;
  font-size: 12px;
  color: #777;
  text-align: left;
}

.blog-card-content-new {
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.blog-read-more img {
  width: 20px;
  height: 20px;
}
@media (max-width: 378px) {
  .Blog-main-blog {
    padding: 20px;
  }
}
@media (max-width: 468px) {
  .Blog-main-blog {
    padding: 10px 30px;
  }
  .blog-section-container-blog {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 25px;
    margin-top: 3%;
  }
  .blog-card-image img {
    height: 200px;
  }
  .blog-card-content-new {
    gap: 20px;
  }
}
@media (max-width: 1024px) {
  .blog-first-section {
    flex-direction: column; /* Stack elements vertically */
    gap: 20px;
    padding-top: 30px;
  }

  .first-blog-section-main {
    display: flex;
    flex-direction: column;
    position: relative; /* Enable positioning of children relative to this container */
  }

  .newest-font {
    position: absolute;
    top: -24px;
    left: 7px;
    z-index: 2; /* Ensure it appears above the image */
  }

  .posted-img {
    order: 1;
  }

  .col-blog-content {
    order: 2;
    margin-top: 10px;
  }

  .posted-img img {
    height: 200px;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blog-section-container-blog {
    grid-template-columns: repeat(2, 1fr);
  }

  .blog-first-section {
    flex-direction: column;
    gap: 30px;
  }
  .blog-card-image img,
  .posted-img img {
    height: 200px;
  }

  .blog-card-image img,
  .posted-img img {
    height: 250px;
  }
}
