.about-us-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  box-sizing: border-box;
  padding-left: 50px; 
}

.about-us-sub {
  width: 50%;
  display: flex;
  flex-direction: column; 

  gap: 20px;
 
}

.about-us-img-container {
  width: 50%;
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

.about-us-img {
  width: 100%;
  height: auto; 
  object-fit: cover; 
}

.about-us-text {
 
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 768px) {
  .about-us-main {
    flex-direction: column; 
    align-items: flex-start; 
    padding-left: 20px; 
    padding-right: 20px;
  }

  .about-us-sub {
    width: 100%; 
  }

  .about-us-img-container {
    display: none; 
  }
}
