.approach-main {
  display: flex;
  justify-content: space-between;
  padding: 100px 80px;
  gap: 30px;
  align-items: stretch;
  width: auto;
  background-color: rgba(237, 237, 237, 1);
}
.img-approach {
  width: 50%;
  height: auto;
  background-image: url("../../utils/images/approach.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.content-approach {
  height: 100%;
  /* max-height: 800px; */
  width: 50%;
  display: flex;
  flex-direction: column;
}
.approach-heading {
  color: rgba(235, 184, 26, 1);
}
.heading-approach-col {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
}
.subheading-approach {
  color: white;
}

.first-card-sec {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 300px;
}

.img-section {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  gap: 20px;
  height: 120px;
  max-height: 30%;
}

.img-section img {
  max-width: 70px;
  max-height: 60px;
  object-fit: contain;
}

.inner-content-approach {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inner-content-approach > Typography:first-child {
  font-weight: 600;
  font-size: 20px;
}

.inner-content-approach > Typography:last-child {
  font-size: 18px;
  line-height: 1.5;
}
.arrow-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-section img {
  height: auto;
}
.grid-container {
  width: 50%;
  display: grid;
  gap: 30px;
  grid-template-columns: auto auto;
}
.heading-approach-col-iner {
  display: none;
}
@media (max-width: 1024px) {
  .img-approach {
    width: 100%;
    background-image: none;
  }

  .heading-approach-col {
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 30px;
  }
  .subheading-approach {
    color: black;
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 20px;
  }

  .approach-main {
    flex-direction: column;
  }
}
@media (max-width: 933px) {
  .grid-container {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 20px;
  }
  .approach-main {
    padding: 40px 30px;
  }
}
@media (max-width: 375px) {
  .approach-main {
    padding: 30px 20px;
  }
}
