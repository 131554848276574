.main-third {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  padding-bottom: 60px;
  background: #EDEDED;

}

.about-us-text1 {
  width: 35%;
  padding-left: 100px;
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.third-sub {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
}

.card-wrapper-about-us {
  display: flex;
  align-items: center;
}

.card-content {
  transition: all 0.5s ease;
  color: #000000;
}

.card-content.active {
  border-color: #B97E12;
  background-color: #B97E12;
  color: white;
}

.side-item-about-us {
  border-left: 4px solid #dfdfdf;
  /* padding-left: 10px;
  margin-bottom: 20px; */
  width: 8%;
  height: 100%;
  border-top-right-radius: 5px;
  transition: all 0.5s ease;
}

.side-item-about-us.active {
  border-color: #B97E12;
  /* background-color: #B97E12; */
}

/* .card-title-about-us,
.card-description-about-us {
  transition: all 0.5s ease;
  color: black; 
  text-align: justify;
}
.card-title-about-us.active{
  color: white;
} */
.card-title-about-us,
.card-description-about-us {
  /* transition: all 0.5s ease; */
  color: black; /* Default text color */
}

.card-content.active .card-title-about-us,
.card-content.active .card-description-about-us {
  color: white; /* Text color when card is active */
}
@media (max-width:768px){
  .main-third {
    flex-direction: column;
    align-items: center;
  }
  .third-sub{
    width: 77%;
  }
  .side-item-about-us{
    height: auto;
  }
  .card-wrapper-about-us{
    align-items: normal;
  }
  .about-us-text1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
  }
}
