.main-fourth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.main-fourth-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.main-fourth-content {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.secnd-sec-4 {
  padding: 0px 55px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.flex-card-in-4 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
  /* margin-bottom: 10%; */
}
.about-card-4 {
  width: calc(50% - 15px);
  height: 400px;
}
.about-content-card-4 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-top: 4%;
}
.about-card-title-4 {
  /* font-family: "Poppins";
    font-weight: 700;
    font-size: 18px; */
  color: white;
  text-align: left;
  margin: 0;
}
.about-content-1-4 {
  /* font-family: "Poppins";
    font-weight: 400;
    font-size: 14px; */
  color: white;
  text-align: left;
  margin: 0;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .flex-card-in-4 {
    flex-direction: column;
  }
  .about-card-4 {
    width: 100%;
    height: 160px;
  }
  .about-content-card-4 {
    margin-top: 5px;
  }
  .main-fourth-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .secnd-sec-4 {
    padding: 0 33px;
  }
}
@media (max-width:426px){
    .about-content-card{
        padding: 18px;
    }
    .about-card-4 {
      width: 100%;
      height: 300px;
    }
}
@media screen and (max-width: 1024px){
    .about-card-title{
        text-align: center;
    }
    .about-content-1{
        text-align: center;
    }
}

.end-content{
    color: #6C7282;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
    text-align: center;
}