.inner-main {
  padding: 30px 80px;
  width: 100%; /* Use 100% instead of 100vw */
  box-sizing: border-box; /* Ensure padding is included in the width */
}
.inner-content-careers {
  width: 60%;
}
.img-section-career {
  background-image: url(../../utils/images/Base1.png);
  height: 70vh;
  background-size: cover;
  background-position: center;
}
.main-inner-careers {
  overflow: hidden;
}
.job-details-position {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 10%;
  top: 43%;
}
.apply-box {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 58%;
  right: 8%;
  gap: 10px;
  padding: 30px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 100%; 
}
.form {
  padding: 0% 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-btn-careers {
  top: 7%;
  left: 6%;
  position: relative;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .inner-content-careers {
    width: 80%;
    padding: 10px 20px;
  }
  .form {
    padding: 0% 23% 0% 10%;
  }
  .apply-box {
    top: 67%;
  }
}
@media (max-width: 768px) {
  .inner-main {
    padding: 30px 20px;
  }
  .inner-content-careers {
    width: 80%;
    padding: 10px 20px;
  }
}
@media (max-width: 426px) {
  .inner-main {
    padding: 30px 00px;
  }
  .inner-content-careers {
    width: 89%;
    padding: 10px 20px;
  }
  .job-details-position {
    top: 30%;
  }
}

@media (max-width: 376px) {
  .inner-main {
    padding: 30px 00px;
  }
  .inner-content-careers {
    width: 83%;
  }
  .job-details-position {
    top: 25%;
  }
  .apply-box {
    padding: 20px;
    top: 69%;
  }
  .form {
    padding: 0% 14% 0% 11%;
  }
}
