.cards-container-1 {
  display: flex;
  justify-content: space-evenly;
  /* align-items: stretch; */
  gap: 20px;
  /* padding: 20px; */
  margin-top: 20px;
  padding-bottom: 40px;
  overflow-x: hidden;
}
.vertical-line-1 {
  position: absolute;
  top: 10%;
  right: -11px;
  height: 90%;
  border-right: 2px solid #6c728240;
}
.card-service-about-us {
  width: 78%;
  margin-top: 20px;
  border-radius: 8px;

  text-align: center;
}
.card-wrapper-about-us-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  
 
}
@media (max-width:768px){
    .cards-container-1{
        flex-direction: column;
    } 
}
