.faq-screen {
  background: #ededed;
  padding: 5% 14%;
}
.faq-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.faq-questions {
  padding: 30px 85px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.icon-pointer {
  cursor: pointer;
}
.faq-answer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  min-height: 50px; 
  padding: 10px 0;
}
.border-bottom {
  margin-top: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
@media(max-width:1024px){
    .faq-screen {
        padding: 40px 70px;
    }
}
@media(max-width:768px){
    .faq-screen {
        padding: 40px 60px;
    }
}
@media(max-width:426px){
    .faq-screen {
        padding: 40px 40px;
    }
    .faq-questions{
        padding: 30px 10px;
    }
}
@media(max-width:375px){
    .faq-screen {
        padding: 40px 35px;
    }
    .faq-questions{
        padding: 30px 0px;
    }
}