.Blog-main {
  background: #ededed;
  padding: 50px 80px;
  overflow: hidden;
}

.font-welcome {
  text-align: center;
}

.blog-first-section {
  display: flex;
  gap: 5%;
  padding-top: 20px;
  justify-content: space-between;
  align-items: stretch;
}

.first-blog-section {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.blog-second-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.posted-img {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.posted-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.posted-text {
  color: #6c7282;
  margin-top: 10px;
  font-size: 14px;
}

.col-blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;
  margin-top: 15px;
}

.readmore-blog {
  display: flex;
  align-items: center;
  gap: 10px;
}

.readmore-blog img {
  width: 20px;
  height: 20px;
}

.first-blog-section,
.col-blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  height: 100%;
}
.blog-section-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 3%;
}

.blog-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.blog-card-content {
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  flex-grow: 1;
}

.blog-description {
  margin-top: 12px;
  text-align: justify;
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  flex-grow: 1;
}

.blog-read-more {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: auto;
}

.blog-card-image {
  position: relative;
  text-align: center;
}

.blog-card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-post-date {
  margin-top: 8px;
  font-size: 12px;
  color: #777;
  text-align: left;
}

.blog-card-content {
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.blog-read-more img {
  width: 20px;
  height: 20px;
}

@media (max-width: 378px) {
  .Blog-main {
    padding: 20px;
  }
}
@media (max-width: 468px) {
  .Blog-main {
    padding: 10px 30px;
  }
}
@media (max-width: 768px) {
  .blog-section-container {
    grid-template-columns: 1fr;
  }

  .blog-first-section {
    flex-direction: column;
    gap: 30px;
  }
  .blog-card-image img,
  .posted-img img {
    height: 200px;
  }
}

/* For screens between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .blog-section-container {
    grid-template-columns: 1fr;
  }

  .blog-first-section {
    flex-direction: column;
    gap: 30px;
  }
  .blog-card-image img,
  .posted-img img {
    height: 200px;
  }

  .blog-card-image img,
  .posted-img img {
    height: 250px; /* Adjust the height for better responsiveness */
  }
}

/* Large Screens: Maintain the current layout */
@media (min-width: 1025px) {
  .blog-section-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
