.service-sub-content {
  padding: 100px;
}
.third-page-service {
  background-color: #b97e12;
  padding: 30px;
}
.secnd-sec {
  padding: 0px 55px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.flex-card-in-service {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  flex-wrap: nowrap;
  /* margin-bottom: 10%; */
}
.flex-card-in {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
 
}
.flex-card-in-1 {
  display: flex;
  
  gap: 31px;
  flex-wrap: nowrap;
}
.about-card-service {
  width: calc(50% - 15px);
  height: 300px;
}
.about-content-card-service {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  margin-top: 4%;
}
.about-card-title {
  /* font-family: "Poppins";
      font-weight: 700;
      font-size: 18px; */
  color: white;
  text-align: left;
  margin: 0;
}
.about-content-1 {
  /* font-family: "Poppins";
      font-weight: 400;
      font-size: 14px; */
  color: white;
  text-align: left;
  margin: 0;
  overflow-y: hidden;
}
.thin-font {
  -webkit-text-stroke: 0.2px #EBB81A;
  color: transparent;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}


@media (min-width:1024px){
    .about-card-service-1{
        width: 425px;
    }
}
@media (max-width: 1024px) and (min-width: 1024px) {
  .about-card-service {
    height: 500px;
  }
  .about-card-service-1{
    width: 266px;
  }
}

@media (max-width: 768px) {
  .flex-card-in {
    flex-direction: column;
  }
  .flex-card-in-1{
    flex-direction: column;
  }
  .about-card-service {
    width: 100%;
    height: 200px;
  }
  .about-content-card-service {
    margin-top: 5px;
  }
  .main-fourth-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .secnd-sec {
    padding: 0 0px;
  }
}
@media (max-width: 400px) {
  .about-content-card-service {
    padding: 18px;
  }
}
@media (max-width: 455px) {
  .about-card-service {
    height: 400px;
  }
  .about-card-service-1{
    height: 400px;
  }
  .service-sub-content {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .about-card-title {
    text-align: center;
  }
}
