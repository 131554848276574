/* CareersMain.css */

.careers-container {
  padding: 30px 90px;
  overflow: hidden;
}

.filter-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 15px;
}
.main-careerfont {
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.job-listings {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 60px;
}

.job-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.flex-border {
  display: flex;

  width: 100%;
  border-right: 1px solid black;
}
.flex-border-last {
  display: flex;

  width: 100%;
}
.job-details {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.job-location {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
.job-name {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}
.job-title {
  margin: 0 0 5px;
  font-size: 18px;
  font-weight: 600;
}
.location-flex {
  display: flex;
  gap: 10px;
}
.location-text {
  font-weight: 600;
}
.job-experience {
  color: #035aa1;
}

.job-type {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.icon {
  margin-right: 5px;
}

.button-careers {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .flex-border {
    border-right: none;
  }

  .filter-section {
    gap: 5px;
    padding: 10px;
    flex-direction: column;
  }

  .job-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .job-details {
    flex-direction: column;
    gap: 10px;
  }

  .button-careers {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .careers-container {
    padding: 20px 35px;
  }
  .job-listings {
    padding: 0px;
  }
  .job-card {
    padding: 10px 20px;
  }
  .job-name,
  .job-location {
    padding: 20px 10px;
  }
}
