.card-contact {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 30px;
}
.bg-img-02{
    height: 630px;
}
@media(max-width:768px){
    .card-contact {
        flex-direction: column;
    }
    .bg-img-02{
        height: auto;
    }
}