/* Default Styles (for screens above 1024px) */
.main-news {
  padding: 50px 80px;
}

.main-heading-news {
  display: flex;
  flex-direction: column;
}

.next-div {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.content-news {
  padding: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

.inner-news {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.latest-news-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.sub-content-news {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #6c7282;
}

.news-button {
  width: 80%;
}

.date-news {
  color: #6c7282;
}

.imge-section-news {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .main-news {
    padding: 30px 60px;
  }

  .next-div {
    /* grid-template-columns: repeat(2, 1fr);  */
    gap: 20px;
  }

  .content-news {
    padding: 15px;
  }

  .inner-news {
    flex-direction: column;
    gap: 15px;
  }

  .latest-news-content {
    align-items: center;
    gap: 15px;
  }

  .sub-content-news {
    gap: 10px;
  }

  .news-button {
    width: 100%;
    height: 35px;
  }

  .date-news {
    font-size: 14px;
  }

  .imge-section-news {
    display: flex;
    order: -1;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .button-div-news {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-news {
    padding: 20px 40px;
  }

  .next-div {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .content-news {
    padding: 15px;
  }

  .inner-news {
    flex-direction: column;
    gap: 15px;
  }

  .latest-news-content {
    align-items: center;
    gap: 15px;
  }

  .sub-content-news {
    gap: 10px;
  }

  .news-button {
    /* width: 100%; */
    width: 50%;
    height: 35px;
  }

  .date-news {
    font-size: 14px;
  }

  .imge-section-news {
    display: flex;
    order: -1;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .main-news {
    padding: 20px;
  }

  .next-div {
    grid-template-columns: 1fr;
  }

  .latest-news-content {
    gap: 10px;
  }

  .sub-content-news {
    gap: 5px;
  }

  .news-button {
    height: 30px;
  }

  .date-news {
    font-size: 12px;
  }

  .imge-section-news {
    display: flex;
    order: -1;
    margin-bottom: 10px;
  }
}
@media (max-width: 375px) {
  .news-button {
    width: 60%;
    height: 30px;
  }
}
