.about-us-second {
  background-color: black;
  padding: 50px 0;
}

.main-heading-about-us {
  color: #ebb81a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.about-us-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  padding: 0 120px;
}

.about-us-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 2px solid #ebb81a; /* Ensures uniformity across cards */
}

.about-us-card > :nth-child(1) {
  margin-bottom: 15px;
}

.about-us-card > :nth-child(2) {
  font-weight: bold;
  margin-bottom: 10px;
}

.imge-section-about-us {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imge-section-about-us img {
  width: 100%; /* Ensures the line spans the card width */
  max-height: 5px; /* Restricts the line height for uniformity */
  object-fit: contain;
}
@media (max-width: 768px) {
  .about-us-div {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Reduced gap for smaller screens */
    padding:10px 50px 0px 50px /* Adjust padding for smaller screens */
  }
}
