/* .kairos {
    font-size: 24px;
    font-weight: bold;
  } */
  .navbar-main {
    padding: 0px 25px 22px;
    background: white;
  }
  .color-ai {
    background: linear-gradient(
      90deg,
      #ebb81a 0%,
      #dfad17 32%,
      #b97e12 68%,
      #fff885 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .kairos {
    margin-left: 19%;
  }
  
  @media (max-width: 800px) {
    .navbar-main {
      padding: 20px 18px;
    }
  }
  
  @media (max-width: 480px) {
    .navbar-main {
      padding: 15px 18px;
    }
  }