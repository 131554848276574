.root {
    background-color: rgba(247, 250, 255, 255);
    width: 100%;
    margin-top: 45px;
  }
  .headingDiv {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(247, 250, 255, 255);
  }
  .mainHeading {
    font-size: 28px;
    font-weight: 600;
    color: #424242;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .description {
    color: #727272;
    justify-content: center;
    text-align: justify;
    font-size: 16px;
  }
  .terms {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .termsHeading {
    text-align: left;
    justify-content: left;
    align-items: left;
  }
  .termsTitle {
    font-size: 1.375rem;
    font-weight: 600;
    color: #424242;
    padding-bottom: 15px;
    text-align: left;
    justify-content: left;
    align-items: left;
  }
  @media (max-width: 599px) {
    .mainHeading {
      font-size: 22px;
    }
    .description {
      font-size: 13px;
    }
    .termsTitle {
      font-size: 13px;
    }
  }
  