.main-content {
  background-color: black;
  width: 100%;
  height: 100%;
}
.padding-content {
  padding: 50px;
}
.main-heading {
  color: #ebb81a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.para-content {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.para-content span {
  display: block;
}
.first-card{
  width:50%;
}
.container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 16px;
  padding: 16px;
  align-items: center;
}
/* .card-container{
  display: flex;
  gap: 30px;
  flex-direction: column;
} */
@media screen and (width: 768px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 30px;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .para-content {
    flex-direction: column; /* Ensure vertical alignment if needed */
  }
  .para-content span {
    display: revert;
    margin-left: 5px;
  }
  .first-card{
    width: 100%;
  }
  
}
.card-container1{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}
.icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card {
  width: 300px;
  height: 130px;
  background-color: #1c1c1c;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  /* transition: transform 0.3s ease, background-color 0.3s ease; */
  cursor: pointer;
}

.img-icon {
  color: white;
  width: 40px;
  height: 25px;
}
.card:hover {
  color: #ebb81a;
}
/* img:hover {
    color:white;
    filter: brightness(0) invert(1);
  } */
.card .icon {
  font-size: 40px;
  color: #ffd700;
  margin-bottom: 16px;
}

.card h3 {
  margin: 0;
}
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    gap: 45px;
    padding: 16px;
  }
  .card {
    width: 100%;
  }
  .padding-content{
    padding: 30px;
  }
}

