.Choose-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2%;
}

.inner-choose {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;
  margin-top: 2%;
}

.inner-content {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 0px 80px;
  width: 80%;
}
.img-choose {
  width: 100%;
}

.choose-content {
  color: black;
  text-align: justify;
}
.inner-sec {
  width: 40%;
}

.side-section {
  margin-top: 9%;
  position: relative;
  background: white;
  width: 45%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: 15px;
  left: -2%;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.1);
  height: 10%;
}

.side-item {
  border-left: 4px solid #f0c040;
  padding-left: 10px;
  margin-bottom: 20px;
}

.side-item .font-header {
  color: #000;
}

.side-item p {
  color: #555;
  text-align: justify;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .side-section {
    width: 35%;
    margin-top: 9%;
  }
  .inner-content {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .inner-content {
    gap: 10px;
    padding: 0px 50px;
    width: auto;
  }
  .Choose-main {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
  }
  .font-welcome {
    text-align: center;
  }

  .inner-choose {
    width: 100%;
    padding: 0 20px;
    gap: 15px;
    margin-top: 3%;
  }

  .img-choose {
    display: none;
  }

  .side-section {
    width: 70%;
    margin-top: 20px;
    left: 0;
  }
}
@media (max-width: 480px) {
  .side-section {
    padding: 6%;
    margin-top: 15px;
    margin-right: 0px;
  }
  .inner-content {
    padding: 10px 30px;
    width: 85%;
  }
}
