.back-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
  cursor: pointer;
}

.main {
 padding: 20px 100px 20px 100px;
  
}
.digital{
  font-weight: 700;
}
.blog-text {
  width: 90%;
  max-width: 1080px;
  text-align: left;

  padding: 10px 60px;
  /* font-size: 48px; */
  font-weight: 700;
  border-radius: 5px;
  font-family: Roboto;
}
.blog-text1 {
  width: 90%;
  max-width: 1080px;
  text-align: left;

  padding: 10px 40px;
  /* font-size: 48px; */
  font-weight: 700;
  border-radius: 5px;
  font-family: Roboto;
}
/* Media query for mobile screens */

.blog-section-one {
  display: flex;
  justify-content: flex-end;
  padding: 0px 50px 0px 50px;
}
.blog-section-sub {
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .responsive-container {
    height: 300px; /* Reduce the height for smaller screens */
    background-size: contain; /* Adjust the image scaling */
  }
  
  .blog-section-one {
    justify-content: flex-start;
  }
  .blog-text1 {
    padding: 10px 0px 0px 0px;
  }
  .blog-text {
    padding: 0px 0px 20px 20px;
  }
  
  .back-btn{
    margin-left: 20px;
    align-items: flex-start;
  }
}
.bg-img{
  height: 500px;
}
@media (max-width:600px){
  .bg-img{
    height: 600px;
  }
  .main {
    padding: 20px 30px 20px 30px;
  }
  .blog-section-sub {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
}
