.main-service {
  padding: 20px 50px 20px 50px;
}

.service-section {
  display: flex;
  justify-content: flex-start;

  /* background-clip: text; */
}
.service-section-text {
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service-section-sub {
  max-width: 82%;
}
@media (max-width: 400px) {
  .main-service {
    padding: 10px 30px 10px 30px;
  }
  /* .service-section-text {
    text-align: center;
  } */
}
.service-img {
  margin-top: 45px;
  /* width: 100%; */
}
.service-sub-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.img-style {
  width: 100%;
}
@media (max-width: 1024px) {
  .img-style {
    width: 100%;
  }
}
@media (max-width: 455px) {
  .img-style {
    display: none;
  }
  .service-section-sub {
    max-width: 100%;
  }
}
/* Container for all cards */
.cards-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Stretch cards to the same height */
  gap: 20px;
  margin-top: 40px;
}

/* Wrapper to include card and vertical line */
.card-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  
}


/* Individual card styling */
.card-service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  flex-grow: 1; 
  
  width: 370px;
  
 
}

/* Placeholder for the icon */
.card-icon {
  display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 margin-bottom: 12px;
}

.icon-placeholder {
  width: 60px;
  height: 60px;
  background-color: #f2a900; /* Yellow color for the placeholder */
  border-radius: 50%;
}

/* Card title */
.card-title {
  text-align: left;
  margin-top: 20px;
}

/* Card subtitle */
.card-subtitle {
  text-align: left;
  margin-top: 20px;
  /* color: #666; */
  margin-bottom: 15px;
}

/* Card description */
.card-description {
  /* font-size: 14px; */
  color: #6c7282;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .service-section-sub {
  max-width: 82%;
} */
/* Card button */
.card-button {
  background: none;
  border: 2px solid #f2a900;
  border-radius: 25px;
  color: #f2a900;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.card-button:hover {
  background: #f2a900;
  color: white;
}

.card-button .arrow {
  font-weight: bold;
  margin-left: 5px;
}

/* Vertical dashed line between cards */
.vertical-line {
  position: absolute;
  top: 5%;
  right: -70px;
  height: 100%;
  border-right: 2px solid #6c728240;
}
@media screen and (max-width: 1024px) {
  .cards-container {
    flex-direction: column;
    gap: 30px;
  }
  .card-service {
    width: 100%;
  }
  .vertical-line {
    display: none; /* Remove the line in smaller screens */
  }
}
/* Ensure responsive layout */
@media screen and (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    gap: 30px;
  }
  .card-description {
    text-align: justify;
  }
  .card-service {
    width: 100%;
  }
  .vertical-line {
    display: none; /* Remove the line in smaller screens */
  }
}
