.footer-content {
  background-color: #f5f5f5;
  padding: 50px 80px 0px;
}

.main-footer-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px dotted black;
}
.social-media {
  display: flex;
  padding: 20px 0px;
}
.copy-footer {
  border-bottom: 1px solid black;
}
.first-footer-seccontent {
  width: 40%;
}
.logo-div {
  flex: 1;
}
.flags img {
  margin: 0 5px;
  width: 30px;
}
.logo-text {
  font-weight: bold;
}

.logo-text .highlight {
  color: #ffcc00;
}

/* .contact-section,
  .quick-links,
  .services,
  .legal {
    flex: 1;
  } */
.kairos-footer {
  margin-left: 10%;
}
.icons-section-footer img {
  width: 20px;
  height: 25px;
  margin: 0 5px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 8px 0;
}

ul li a {
  text-decoration: none;
  color: #000;
}

ul li a:hover {
  text-decoration: underline;
}
.icons-section-footer {
  display: flex;
  gap: 5px;
}
.copyright {
  background-color: #000;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}
.contact-section {
  display: flex;
  margin-top: 17%;
  justify-content: space-between;
  gap: 2%;
}
.location-footer {
  margin-top: 15%;
  padding-bottom: 20px;
}
@media (max-width: 1024px) {
  .first-footer-seccontent {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .main-footer-div {
    flex-direction: column;
    align-items: center;
  }
  /* .contact-location{
    display: flex;
    justify-content: space-between;
  } */
  .contact-sec-768 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .contact-section {
    gap: 10px;
  }

  .first-footer-seccontent,
  .quick-links,
  .services,
  .legal {
    width: 100%; /* Stack sections vertically */
    text-align: left; /* Center-align text for smaller screens */
  }
  .logo-footer {
    text-align: center;
  }
  .location-footer {
    margin-top: 60px;
    padding-bottom: 20px;
  }

  .contact-section {
    flex-direction: column;
    margin-top: 40px;
  }
  .social-media {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .contact-location {
    flex-direction: column;
    align-items: center;
  }
  .footer-content {
    padding: 10px 20px;
  }

  .icons-section-footer img {
    width: 15px;
    height: 20px;
  }

  .flags img {
    width: 20px;
  }

  .social-media {
    gap: 10px;
  }
  .location-footer {
    margin-top: 60px;
  }
}
