.welcome-main-heading {
  padding: 50px 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.font-welcome-about{
  text-align: left;
}
.RFP {
  padding: 3px 20px;
  text-transform: none;
}
.right-content {
  position: relative;
}

.experience-section {
  position: absolute;
  bottom: -45px;
  left: -4%;
  background: white;
  padding: 40px 50px;
  z-index: 2;
  display: flex;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 0px 25px 0px 25px; /* Only round top-right and bottom-left corners */
}

.inner-experience-content {
  display: flex;
  gap: 20px;
}
.experience-content {
  display: flex;
  flex-direction: column;
}

.welcome-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

.left-section {
  flex: 1 1 50%;
  position: relative;
  padding: 20px 50px 0px 80px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.right-section {
  flex: 1 1 50%;
  /* padding: 20px; */
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.img-mask {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left-heading {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sub-heading {
  width: 100%;
}

.welcome-content {
  text-align: left;
  color: rgba(108, 114, 130, 1);
  flex-grow: 1;
}

.gapping-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

@media (max-width: 1200px) {
  .experience-section {
    padding: 34px 14px;
    bottom: -58px;
    left: -9%;
  }
}

@media (max-width: 768px) {
  .right-section {
    display: none;
  }
  .welcome-main-heading {
    justify-content: center;
  }
  .left-section {
    width: 100%;
    padding: 0px 50px;
  }
  .left-heading {
    text-align: center;
  }
  .welcome-content {
    text-align: center;
  }
  .font-welcome-about{
    text-align: center;
  }
}

@media (max-width: 480px) {
  .welcome-main-heading {
    padding: 20px;
    margin-top: 15px;
    justify-content: center;
  }
  .home-button-submit {
    font-size: 14px;
    /* margin-top: 10px; */
  }
  .left-heading {
    gap: 5px;
  }
  .welcome-flex {
    padding-bottom: 20px;
  }
  .left-section {
    padding: 10px 30px;
  }
  .font-welcome {
    line-height: 1.3;
  }
}
