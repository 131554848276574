.img-sec {
  height: 80%;
  background-image: url(../../utils/images/Contactusmain.png);
}
.main-contactus {
  padding: 50px 80px;
}
.contactus-form {
  display: flex;
  flex-direction: column;
}
.contactus-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.heading-section-contact {
  text-align: center;
}
.next-contact {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0% 20%;
}
.support-section {
  display: flex;
  justify-content: space-between;
  flex-grow: auto auto auto;
}
.inner-section {
  display: flex;
  flex-direction: column;
}
.email-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bg-img-1 {
  height: 600px;
}
@media (max-width: 768px) {
  .bg-img-1 {
    height: 410px;
  }
}
@media (max-width: 426px) {
  .bg-img-1 {
    height: 520px;
  }
}
@media (max-width: 375px) {
  .bg-img-1 {
    height: 600px;
  }
}
@media (max-width: 1024px) {
  .main-contactus {
    padding: 40px 60px;
  }
}
@media (max-width: 768px) {
  .main-contactus {
    padding: 40px 50px;
  }
  .next-contact {
    padding: 0% 10%;
    text-align: justify;
  }
}
@media (max-width: 426px) {
  .main-contactus {
    padding: 40px 40px;
  }
  .next-contact {
    padding: 0% 5%;
  }
}
