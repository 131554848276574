.main-div {
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(237, 237, 237, 1);
}

.working-image {
  width: 100vw;
}
.home-heading-section {
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  text-align: center;
}
.main-homefont {
  text-align: center;
  font-family: "Roboto";
  font-weight: 600;
  background: linear-gradient(69.59deg, #dfad17 17.63%, #000000 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.time-to-value {
  font-style: italic;
}
.arrow-home {
  align-items: center;
  height: auto;
  width: auto;
}
@media (min-width: 1200px) {
  .responsive-break-01 {
    display: block;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .responsive-break {
    display: block;
  }
  .home-heading-section {
    padding: 20px 30px;
  }
  .arrow-home{
    width: 5%;
  }
}
@media (max-width: 700px) {
  .home-heading-section {
    padding: 10px 20px;
  }
  .main-homefont {
    display: block;
  }
  .arrow-home {
    width: 10%;
  }
}
