.main-industries {
  padding: 30px 60px ;
}
.main-industries-card {
  padding-left: 10%;
  margin-bottom: 20px;
}

.digital{
    font-weight: 700;
}

.card-industries {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: auto; 
}

.card-main {
  display: flex;
  width: 100%;
}

.card-image {
  flex: 1;
  max-width: 400px;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content-container {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading-oil {
  color: #b97e12;
  font-weight: 700;
  margin-bottom: 10px;
}

.content-sub {
  color: #6c7282;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 768px) {
  .card-industries {
    flex-direction: column;
    height: auto;
  }
  .card-main {
    display: flex;
    flex-direction: column;
  }
  .card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12%;
  }

  .card-image {
    width: 80%;
    height: auto;
  }
  .card-content-container {
    flex: 1;
    padding: 15px;
    gap: 20px;
  }

  .button-container {
    justify-content: center;
  }
  .heading-oil{
    text-align: center;
  }
}

@media (max-width: 480px) {
  .main-industries {
    padding: 20px 30px;
  }
  .main-industries-card {
    padding-left: 0px;
  }
  .card-image {
    padding-left: 9%;
  }
}
